import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0e5ef2e4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "news-public-page"
};
var _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "content-builder",
    innerHTML: _ctx.data.content
  }, null, 8
  /* PROPS */
  , _hoisted_2)]);
}